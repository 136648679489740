import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import "firebase/compat/storage";
import {serverTimestamp} from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCSWodr0rKs594cRz-xKeQjl-dCatoN2vU",
    authDomain: "pipewrench.firebaseapp.com",
    projectId: "pipewrench",
    storageBucket: "pipewrench.appspot.com",
    messagingSenderId: "1036591877463",
    appId: "1:1036591877463:web:6b9436539c3c0c1b00c212"
  };

  firebase.initializeApp(firebaseConfig)

 // init firebase
 firebase.initializeApp(firebaseConfig)
 const projectAuth = firebase.auth();
 const projectFirestore = firebase.firestore()
 const projectStorage = firebase.storage()
 const timestamp = serverTimestamp

 export { projectAuth, projectFirestore, projectStorage, timestamp }

