import { createRouter, createWebHistory } from 'vue-router'
import { projectAuth } from '../firebase/config'
//import Signup from '../views/Signup.vue'
//import Login from '../views/Login.vue'
//import Dashboard from '../views/Dashboard.vue'
//import Overview from '../views/Overview.vue'

// auth guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
   next({ name: 'SignIn' })
  }
  next()
 }

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: () => import( '@/views/SignIn.vue')
  },

  

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: requireAuth,
    children: [

      {
        path: '/overview',
        name: 'DashboardOverview',
        component: () => import('@/views/Overview.vue'),
        beforeEnter: requireAuth,
      },

      {
        path: '/messages',
        name: 'Messages',
        component: () => import('@/views/Messages.vue'),
        beforeEnter: requireAuth,
        children: [

          {
            path: '/messages/inbox',
            name: 'MessagesInbox',
            component: () => import ('@/views/messages/MessagesOverview.vue')
          }

        ]
      },

      {
        path: '/projects',
        name: 'Projects',
        component: () => import ('@/views/Projects.vue'),
        children: [
          {
            path: '/projects/overview',
            name: 'ProjectsOverview',
            component: () => import ('@/views/projects/ProjectsOverview.vue'),
          },
          {
            path: 'projects/create',
            name: 'ProjectsCreate',
            component: () => import('@/views/projects/CreateProject.vue')
          },

          {
            path: '/projects/:id',
            name: 'Project',
            component: () => import ('@/views/projects/Project.vue'),
            beforeEnter: requireAuth,
            children: [
              {
                path: '/projects/:id/overview',
                name: 'ProjectOverview',
                component: () => import('@/views/projects/project/Overview.vue'),
              },
              {
                path: '/projects/:id/tasks',
                name: 'ProjectTasks',
                component: () => import('@/views/projects/project/Tasks.vue')
              },
              {
                path: 'projects/:id/files',
                name: 'ProjectFiles',
                component: () => import('@/views/projects/project/Files.vue')
              },
              {
                path: 'projects/:id/time-tracking',
                name: 'ProjectTime',
                component: () => import('@/views/projects/project/TimeTracking.vue')
              }
            ]
          },
        ]
      },

      {
        path: '/checkout',
        name: 'Checkout',
        component: () => import('@/views/Checkout.vue'),
      },

      {
        path: '/successpw5hadd',
        name: 'SuccessFive',
        component: () => import('@/views/checkout/SuccessFive.vue')
      },

      {
        path: '/successpw10hadd',
        name: 'SuccessTen',
        component: () => import('@/views/checkout/SuccessTen.vue')
      },

      {
        path: '/successpw30hadd',
        name: 'SuccessThirty',
        component: () => import('@/views/checkout/SuccessThirty.vue')
      },

      {
        path: '/time',
        name: 'TimeOverview',
        component: () => import('@/views/time/TimeOverview.vue')
      },

      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Profile.vue'),
        children: [
          {
            path: '/profile/overview',
            name: 'ProfileOverview',
            component: () => import('@/views/profile/ProfileOverview.vue')
          },
          {
            path: '/profile/hours',
            name: 'HoursOverview',
            component: () => import('@/views/profile/HoursOverview.vue')
          },

          {
            path: '/profile/consultant',
            name: 'ConsultantOverview',
            component: () => import('@/views/profile/ConsultantOverview.vue')
          }
        ]
      }


    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
